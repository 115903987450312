.App {
  background-color: rgb(60, 47, 94);
  padding: 20px;
}

.Header {
  display: flex;
  justify-content: space-between;
  background: #303056;
}

.Menu {
  display: flex;
  justify-content: space-between;
  background: #303056;
}

.MobileMenu {
  display: flex;
  justify-content: space-between;
  background: #303056;
}

.BackgroundImage{
  opacity: 0.06;
}

.Home {
  position: relative;
}
.Artist {
  position: relative;
}
.Mint {
  position: relative;
}
.CallsToAction {
  float: left;
  position: absolute;
  top: 120px;
  z-index: 1000;
}

.mask {
  background: rgba(48, 48, 86, .5);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-attachment: fixed;
}

video {
  min-width: 100%;
  min-height: 100%;
}

.bg-image {
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
}


.networkItem:hover {
  background-color: #7900ff;
  color:white;
}


